import React from "react";
import '../../../../../common/AccountPageStateStyle.css';
import {Container, InfoIcon, SuccessIcon, WarningIcon} from "@myob/myob-widgets";
import OpenBankingAccountResultTable from "./OpenBankingAccountResultTable";

const OpenBankingAccountSummaryContainer = ({resultStatus, accounts}) => {
  const getTitle = (Icon, color, text) => (
    <div className="title-container" style={{ fontWeight: 500 }}>
      <Icon color={color} style={{marginRight: '8px'}}/>
      <span>{text}</span>
    </div>
  );

  const soloDescription = (
      <div className="description-container" style={{display: 'block', width: '100%', clear: 'both'}}>
        <p style={{marginBottom: '16px'}}>
          A couple of things need to happen for your transactions to show into Solo:
        </p>
        <p style={{fontWeight: 'bold', marginTop: '16px', marginBottom: '8px'}}>
          We need a new transaction
        </p>
        <p style={{marginBottom: '16px'}}>
          You'll need some money to go in or out of the account for us to finish the connection.
        </p>
        <p style={{fontWeight: 'bold', marginTop: '16px', marginBottom: '8px'}}>
          Link the account to a Solo category
        </p>
        <p>
          We'll let you know when and how to do this.
        </p>
      </div>
  );

  const resultStatuses = {
    unsupportedAccounts: {
      title: getTitle(WarningIcon, "warning", "Unsupported accounts"),
      description: (
        <>
          <div className="description-container">
            <p>
              We're unable to connect these types of accounts.
            </p>
          </div>
        </>
      )
    },
    accountAlreadyOnboarded: {
      title: getTitle(InfoIcon, "info", "Already connected"),
      description: (
        <>
          <div className="description-container">
            <p>
              Nothing's changed for these accounts.
            </p>
          </div>
        </>
      )
    },
    allSuccess: {
      title: getTitle(SuccessIcon, "success", "Account connected"),
      description: (
        <>
          {soloDescription}
        </>
      ),
    },
    selected: {
      title: getTitle(SuccessIcon, "success", "Accounts connected"),
      description: (
        <>
          {soloDescription}
        </>
      ),
    },
    unselected: {
      title: getTitle(WarningIcon, "warning", "Not connected"),
      description: (
        <>
          <div className="description-container">
            <p>
              You've reached the maximum of 2 connected accounts. We will not save data for these accounts.
            </p>
          </div>
        </>
      ),
    },
  }

  return (
    <Container>
      {resultStatuses[resultStatus]?.title}
      <OpenBankingAccountResultTable accountsData={accounts}/>
      {resultStatuses[resultStatus]?.description}
    </Container>
  );
};

export default OpenBankingAccountSummaryContainer;
