import accountsData from './data/feedAccountDetail.json'
import accountsDataWithUnsupported from './data/feedAccountDetailWithUnsupported.json'
import migratingAccountResponse from './data/migrateFinaliseData.json'
import migrateResult from './data/migrateResult.json'
import applicationData from './data/applicationData.json'
import passcode from './data/passcode.json'
import onboardSummaryPartialFailure from './data/onboardSummaryPartialFailure.json'
import onboardSummaryAllSuccess from './data/onboardSummaryAllSuccess.json'
import onboardSummaryAllFailure from './data/onboardSummaryAllFailure.json'
import consentIdData from './data/consentIdData.json'

const fetchApplicationData = async (code) => {
  return applicationData;
}

const fetchApplication = async (appId) => {
  return applicationData;
}

const fetchRedirectURL = async (requestBody) => {
  return "/onboard/finalise?code=1234&state=YXBwbGljYXRpb25JZD1hcHBsaWNhdGlvbklkJnByb2R1Y3RSZWRpcmVjdFVybD1odHRwczovL2dvb2dsZS5jb20&source=ProprietaryAPI";
};

const fetchAccountDataForMigration = async () => {
  return migratingAccountResponse;
}

const fetchAccountData = async (code, state, source) => {
  if (code === 'error') throw new Error('error')
  if (code === 'allOnboarded') return { ...accountsData, accounts: accountsData.accounts
      .map(account =>
      {
        account.onboarded = true
        return account
      })
  }
  if (code === 'noneOnboarded') return { ...accountsData, accounts: accountsData.accounts
      .map(account =>
      {
        account.onboarded = false
        return account
      })
  }
  let oneAccount = {
    "accounts": [
      {
        "id": "",
        "externalId": "eyJjb2RlIjoiQyIsImlkIjoiMDAwMDIxMjM0MDAxMjM0MDAwMCJ10",
        "name": "The Business Spending False",
        "number": "12-1234-1234567-123",
        "type": "BankAccount",
        "bsb": "",
        "source": "API",
        "countryId": "NZ",
        "institutionId": "BNZ",
        "currency": "NZD",
        "balance": "86,878,597.21",
        "onboarded": false
      }]}

  let twoAccounts = {
    "accounts": [
      {
        "id": "",
        "externalId": "eyJjb2RlIjoiQyIsImlkIjoiMDAwMDIxMjM0MDAxMjM0MDAwMCJ10",
        "name": "The Business Spending False",
        "number": "12-1234-1234567-123",
        "type": "BankAccount",
        "bsb": "",
        "source": "API",
        "countryId": "NZ",
        "institutionId": "BNZ",
        "currency": "NZD",
        "balance": "86,878,597.21",
        "onboarded": false
      },
      {
        "id": "",
        "externalId": "eyJjb2RlIjoiQyIsImlkIjoiMDAwMDIxMjM0MDAxMjM0MDAwMCJ10",
        "name": "The Business Spending False",
        "number": "12-1234-1234567-123",
        "type": "BankAccount",
        "bsb": "",
        "source": "API",
        "countryId": "NZ",
        "institutionId": "BNZ",
        "currency": "NZD",
        "balance": "86,878,597.21",
        "onboarded": false
      }]}

  if (code === 'limitExceeded') return { ...accountsData, accounts: accountsData.accounts, bankfeedLimit: 5, availableFeeds: 3}
  if (code === 'allSuccess') return { ...accountsData, accounts: accountsData.accounts, bankfeedLimit: 5, availableFeeds: 5}
  if (code === 'oneAccount') return { ...oneAccount, accounts: oneAccount.accounts, bankfeedLimit: 1, availableFeeds: 1}
  if (code === 'twoAccounts') return { ...twoAccounts, accounts: twoAccounts.accounts, bankfeedLimit: 2, availableFeeds: 2}
  if (code === 'noAvailableFeed') return { ...accountsData, accounts: accountsData.accounts, bankfeedLimit: 0, availableFeeds: 0}
  if (code === 'unsupported') return accountsDataWithUnsupported;
  return accountsData;
};

const fetchPasscode = async (code) => {
  return passcode;
};

const getBankingPortalUrl = async (code) => {
  return "/"
}
const revokeConsent = async (applicationId, consentId) => {}
const createFeeds = async (applicationId, accounts) => {}

const updateApplicationStatus = async (applicationId, applicationStatus) => {}

const fetchOnboardSummary = async (applicationId, consumptionId) => {

  if (consumptionId === "partialFailure")
    return onboardSummaryPartialFailure;
  if (consumptionId === "allFailure")
    return onboardSummaryAllFailure;

  return onboardSummaryAllSuccess;
};

const migrateFeeds = async () => {
  return migrateResult
}

const fetchConsentId = async (applicationId) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(consentIdData);
    }, 5000);
  });
}

export {
  fetchApplicationData,
  fetchApplication,
  fetchRedirectURL,
  fetchAccountData,
  fetchPasscode,
  fetchOnboardSummary,
  getBankingPortalUrl,
  updateApplicationStatus,
  createFeeds,
  fetchAccountDataForMigration,
  migrateFeeds,
  revokeConsent,
  fetchConsentId
}
