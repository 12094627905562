import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import OnboardInitiate from "./pages/initiate/OnboardInitiate";
import OnboardOpenBankingInitiate from "./pages/initiate/OnboardOpenBankingInitiate";
import OnboardFinaliseContainer from "./pages/finalise/OnboardFinaliseContainer";
import OnboardSummaryContainer from "./pages/summary/OnboardSummaryContainer";
import {apiContext} from "./ApiContext";
import {api} from "./Dependencies";
import RootView from "./pages/RootView";
import { Buffer } from "buffer/";
import MigrateFinaliseContainer from "./pages/finalise/MigrateFinaliseContainer";
import OpenBankingRedirectErrorView from "./pages/open-banking-error/OpenBankingRedirectErrorView";
import { ToastProvider } from "@myob/myob-widgets";
import ConsentManagementErrorView from "./pages/consent-management-error/ConsentManagementErrorView";
import ConsentManagementSuccessView from "./pages/consent-management-success/ConsentManagementSuccessView";
import ConsentManagementDashboard from "./pages/initiate/ConsentManagementDashboard";

window.Buffer = window.Buffer || Buffer;

function App() {
  let query = new URLSearchParams(useLocation().search);

  return (
    <apiContext.Provider value={ api }>
      <Switch>
        {/*Passcode are built for Exo-ANZ PoC and is currently archiving in the archive folder
        Tests fail after Feelix update and 0Auth flow issue will also need to be solved*/}
        {/*<Route exact path="/onboard">*/}
        {/*  <Passcode code={query.get("code")} redirectUrl={query.get("redirectUrl")}/>*/}
        {/*</Route>*/}
        <Route path="/onboard/initiate">
          <OnboardInitiate
            portalCode={query.get("code")}
            inputApplicationId={query.get("application_id")}
            redirectUrl={query.get("redirectUrl")}/>
        </Route>
        <Route path="/onboard/finalise">
          <OnboardFinaliseContainer
            authCode={query.get("code")}
            consentId={query.get("id")}
            onboardingApplicationData={query.get("state")}
            error={query.get("error")}/>
        </Route>
        <Route path="/migration/finalise">
          <ToastProvider>
            <MigrateFinaliseContainer
              authCode={query.get("code")}
              consentId={query.get("id")}
              migrationApplicationData={query.get("state")}
              error={query.get("error")}/>
          </ToastProvider>
        </Route>
        <Route path="/onboard/openbanking/initiate">
          <OnboardOpenBankingInitiate
            applicationId={query.get("applicationId")}
            productRedirectUrl={query.get("redirectUrl")}
            connectedUrl={query.get("connectUrl")}/>
        </Route>
        <Route path="/onboard/summary">
          <OnboardSummaryContainer
            applicationId={query.get("applicationId")}
            consumptionId={query.get("consumptionId")}
            redirectUrl={query.get("redirectUrl")}/>
        </Route>
        <Route path="/onboard/openbanking/error">
          <OpenBankingRedirectErrorView
            errorCode={query.get("error")}/>
        </Route>
        <Route path="/consent-management/openbanking/initiate">
          <ConsentManagementDashboard
            connectUrl={query.get("connectUrl")}
            productRedirectUrl={query.get("redirectUrl")}/>
        </Route>
        <Route path="/consent-management/openbanking/error">
          <ConsentManagementErrorView />
        </Route>
        <Route path="/consent-management/openbanking/success">
          <ConsentManagementSuccessView />
        </Route>
        <Route path="/*">
          <RootView/>
        </Route>
      </Switch>
    </apiContext.Provider>
  );
}

export default App;
