import * as PropTypes from "prop-types";
import { Connect} from 'connect-web-sdk';
import React from "react";
import ConnectPage from "../consent-management/ConnectPage";
export default function ConsentManagementDashboard({ connectUrl , productRedirectUrl }) {
  const connectEventHandlers = {
    onDone: () => {
      console.log("Connect.destroy() called")
      Connect.destroy();
    },
    onCancel: () => {
      if (productRedirectUrl){
        console.log("Connect.destroy() called")
        Connect.destroy();
        window.location.href = productRedirectUrl
      }
    },
    onError: () => {},
    onRoute: () => {},
    onUser: () => { },
    onLoad: () => {}
  };

  return (<ConnectPage connectedUrl={connectUrl} connectEventHandlers={connectEventHandlers}></ConnectPage>);
}

ConsentManagementDashboard.propTypes = {
  connectedUrl: PropTypes.string,
  productRedirectUrl: PropTypes.string,
};
