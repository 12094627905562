import React, {useContext, useEffect, useState} from "react";
import Loading from "../../common/Loading";
import ErrorView from "../../common/ErrorView";
import {apiContext} from "../../ApiContext";
import SelectAccountsContainer from "./select-accounts/SelectAccountsContainer";
import FeedConfirmationResultContainer from "./feed-confirmation/openbanking/FeedConfirmationResultContainer";
import {FullStory} from "@fullstory/browser";
import * as _ from 'lodash';
import * as PropTypes from "prop-types";
import {api} from "../../Dependencies";

function OnboardFinaliseContainer({ authCode, consentId, onboardingApplicationData, error }) {

  const [isLoading, setIsLoading] = useState(true)
  const [errorType, setErrorType] = useState(null)
  const [feedAccountDetails, setFeedAccountDetails] = useState()
  const [accounts, setAccounts] = useState([])
  const [accountsToOnboard, setAccountsToOnboard] = useState([])
  const [isFeedCreated, setIsFeedCreated] = useState(false)
  const [isBankFeedLimitExceeded, setIsBankFeedLimitExceeded] = useState(true)
  const api = useContext(apiContext)

  const areAllAccountsOnboarded = () => {
    return !_.isEmpty(accounts) && _.every(accounts, account => account.onboarded);
  }
  const accountsAlreadyOnboarded = (accounts) => {
    return _.filter(accounts, account => account.onboarded);
  }

  const unsupportedAccounts = (accounts) => {
    return _.filter(accounts, account => account.unsupportedDescription);
  }

  const getAccountsNotOnboarded = (accounts) => {
    return _.filter(accounts, account => !account.onboarded && !account.unsupportedDescription);
  }

  const isFromOpenBanking = () => {
    return getDecodedValue("source")?.toLowerCase() === "OpenBanking".toLowerCase();
  }

  const revokeConsentForOpenBanking = async () => {
    try {
      isFromOpenBanking() && consentId && await api.revokeConsent(getDecodedValue("applicationId"), consentId);
    } catch {
      setErrorType('openBankingFinaliseError');
    }
  }

  const handleOpenBankingError = async () => {
    setErrorType('openBankingFinaliseError');
  }

  const createFeeds = async (selectedAccounts) => {
    setIsLoading(true);
    setAccountsToOnboard(selectedAccounts);

    try {
      await api.createFeeds(getDecodedValue("applicationId"), selectedAccounts);
      setIsFeedCreated(true);
    } catch (err) {
      if (isFromOpenBanking()) {
        await handleOpenBankingError();
      } else {
        err.status === 409 ? setErrorType('applicationCannotProceed') : setErrorType('finalisationFailed');
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const getFeedAccountDetails = async () => {
      let accountData;
      try {
        accountData = await api.fetchAccountData(authCode, onboardingApplicationData, consentId);
        setFeedAccountDetails(accountData);
        setAccounts(accountData.accounts);
      } catch (err) {
        if (err.message !== 'Unauthorized' && isFromOpenBanking()) {
          await handleOpenBankingError();
          return;
        }
        if (err.status === 409) {
          setErrorType('applicationCannotProceed');
        } else setErrorType('applicationFailed');
        return
      }

      if (_.isEmpty(accountData.accounts)) {
        setErrorType('noAccountsError');
        return;
      }

      if (isFromOpenBanking()) {
        const accountsNotOnboarded = getAccountsNotOnboarded(accountData.accounts);
        if (accountData.availableFeeds == null || accountsNotOnboarded.length <= accountData.availableFeeds) {
          setIsBankFeedLimitExceeded(false);
          _.isEmpty(accountsNotOnboarded) ? await revokeConsentForOpenBanking() : await createFeeds(accountsNotOnboarded);
        }
      }
      setIsLoading(false)
    };

    const declineApplication = async () => {
      try {
        await api.updateApplicationStatus(getDecodedValue("applicationId"), "ConsentDeclined");
        setErrorType('accountsNotConnected');
      } catch {
        setErrorType('applicationFailed');
      }
    }

    error ? declineApplication() : getFeedAccountDetails();
  }, []);

  const atobDecoding = (data) =>{
    return Buffer.from(data, "base64").toString();
  }

  const getDecodedValue = (key) => {
    const decodedAppData = atobDecoding(onboardingApplicationData).split('&');
    const dataPairs = decodedAppData.map(dataPair => dataPair.split('='));
    let pair = dataPairs.find(dataPair => dataPair[0] === key);
    return pair && pair[1];
  }

  const redirectUrl = getDecodedValue("productRedirectUrl")

  const renderError = () => {
    FullStory('trackEvent', {
      name: 'Onboard Finalise Error',
      properties: {
        error_code: errorType,
        source: getDecodedValue("source")
      },
    })
    return <ErrorView type={errorType} redirectUrl={redirectUrl}/>
  }

  const prepareAccountsForOnboarding = (accounts) => {
    return isFromOpenBanking() ? _.sortBy(accounts, function(account) {
      return [account.onboarded, account.unsupportedDescription];
    }) :
      getAccountsNotOnboarded(accounts);
  }

  const renderFeedsOnboardingView = () => {
    return (isFeedCreated || areAllAccountsOnboarded() || getAccountsNotOnboarded(accounts).length === 0) ?
      (
        <FeedConfirmationResultContainer
          source={getDecodedValue("source")}
          areAllAccountsOnboarded={areAllAccountsOnboarded()}
          redirectUrl={redirectUrl}
          accountsNotYetOnboarded={getAccountsNotOnboarded(accounts)}
          selectedOnboardAccounts={accountsToOnboard}
          alreadyOnboardedAccounts={accountsAlreadyOnboarded(accounts)}
          unsupportedAccounts={unsupportedAccounts(accounts)}
        />
      ) :
      (
        isBankFeedLimitExceeded && <SelectAccountsContainer
          accounts = {prepareAccountsForOnboarding(accounts)}
          bankfeedLimit = {feedAccountDetails.bankfeedLimit}
          availableFeeds = {feedAccountDetails.availableFeeds}
          productRedirectUrl={redirectUrl}
          subscriptionUpgradeUrl={feedAccountDetails.subscriptionUpgradeUrl}
          onSubmit={createFeeds}
          onCancel={revokeConsentForOpenBanking}
          source={getDecodedValue("source")}
        />
      )
  }

  return (
    <div>
      {
        errorType ? renderError() :
          isLoading ?
            <Loading message={"Fetching your feed info..."}/> :
            renderFeedsOnboardingView()
      }
    </div>
  )
}

OnboardFinaliseContainer.propTypes = {
  authCode: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  consentId: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onboardingApplicationData: PropTypes.string.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default OnboardFinaliseContainer;
