import React from "react";
import {Button, ButtonRow, Card} from "@myob/myob-widgets";
import "../../../common/Common.css"
import "../../../common/AccountPageStateStyle.css";
import * as PropTypes from "prop-types";

function OpenBankingFeedConfirmationView ({redirectUrl, summarySection}) {
  const handleOnClick = () => {
    window.location.href = redirectUrl
  }

  return (
    <div className='page-block animate'>
      <Card size={'xxl'}>
        {summarySection}
        {!redirectUrl && (
          <div style={{textAlign: 'center', marginTop: '20px'}}>
            <p>
              Close this window
            </p>
          </div>
        )}
      </Card>
      {redirectUrl && (
        <ButtonRow className="animate">
          <Button
            className="finish-confirmation-for-open-banking"
            style={{marginTop:'20px'}}
            type="primary"
            data-testid="return to bank feeds page button"
            onClick={handleOnClick}>
            Return to bank feeds page
          </Button>
        </ButtonRow>
      )}
    </div>
  );
}

OpenBankingFeedConfirmationView.propTypes = {
  redirectUrl: PropTypes.string,
  summarySection: PropTypes.element
};
export default OpenBankingFeedConfirmationView;
