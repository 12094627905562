import React from "react";
import {Table} from "@myob/myob-widgets";

function OpenBankingAccountResultTable({accountsData}) {
  const tableColumns = [
    {key: 'name', description: 'Account name', visible: true, align: 'left', valign: 'middle'},
    {
      key: 'number',
      description: 'Account number',
      visible: true,
      align: 'left',
      valign: 'middle'
    },
  ];

  return (
    <Table variant="bordered">
      <Table.Header>
        {tableColumns.map(column => (<Table.HeaderItem key={column.description}>{column.description}</Table.HeaderItem>))}
      </Table.Header>
      <Table.Body>{accountsData.map(account => (
        <Table.Row key={account.id}>
          {tableColumns.map(column =>
            (<Table.RowItem columnName={column.description} textWrap='wrap'>{account[column.key]}</Table.RowItem>))}
        </Table.Row>)
      )}
      </Table.Body>
    </Table>
  );
}

export default OpenBankingAccountResultTable;
