import {fetchResponse} from "../common/Authentication";

const fetchApplicationData = async (code) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ portalCode: code }),
  };

  const res = await fetchResponse("/api/applications", requestOptions);
  return res.json();
}

const fetchApplication = async (applicationId) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };
  const res =  await fetchResponse(`/api/applications/${applicationId}`, requestOptions)
  return res.json();
}

const fetchRedirectURL = async (requestBody) => {
  const requestOptions = {
    "method": "POST",
    "headers": { "Content-Type": "application/json" },
    "body": JSON.stringify(requestBody)
  };
  const res = await fetchResponse("/api/onboard/initiate", requestOptions);
  return res.json();
};

const fetchAccountData = async (code, state, consentId) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ code, state, consentId }),
  };
  const res = await fetchResponse("/api/onboard/finalise", requestOptions);
  return res.json();
};

const fetchAccountDataForMigration = async (code, state, consentId) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ code, state, consentId }),
  };

  const res = await fetchResponse("/api/migration/finalise", requestOptions);
  return res.json();
};

const fetchPasscode = async (code) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ portalCode: code }),
  };

  const res = await fetchResponse("/api/passcodes", requestOptions);
  return res.json();
};

const getBankingPortalUrl = async () => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  }
  let res = await fetch("/api/bankingPortalUrl", requestOptions)
  if (!res.ok) {
    throw res;
  }
  let jsonRes = await res.json();
  return jsonRes.bankingPortalUrl;
}

const updateApplicationStatus = async (applicationId, applicationStatus) => {
  const requestOptions = {
    "method": "PATCH",
    "headers": { "Content-Type": "application/json" },
    "body": JSON.stringify({applicationId, applicationStatus}),
  };

  return await fetchResponse("/api/applications", requestOptions)
}

const createFeeds = async (applicationId, accounts) => {
  const requestOptions = {
    "method": "POST",
    "headers": {"Content-Type": "application/json"},
    "body": JSON.stringify({applicationId, accounts})
  };

  return await fetchResponse("/api/feeds", requestOptions)
}

const migrateFeeds = async (applicationId, accounts) => {
  const requestOptions = {
    "method": "POST",
    "headers": {"Content-Type": "application/json"},
    "body": JSON.stringify({applicationId, accounts})
  };

  const res = await fetchResponse("/api/migration/feeds", requestOptions)
  return res.json();
}

const fetchOnboardSummary = async (applicationId, consumptionId) => {
  const path = `/api/onboard/summary?applicationId=${applicationId}&consumptionId=${consumptionId}`
  const requestOptions = {
    "method": "GET",
  };
  const res = await fetch(path, requestOptions)
  if (res.ok) {
    return res.json();
  }
  if (res.status === 404)
    return {accountResults:[]}
  throw res;
}

const revokeConsent = async (applicationId, consentId) => {
  const requestOptions = {
    "method": "DELETE"
  };
  return await fetchResponse(`/api/application/${applicationId}/consents/${consentId}`, requestOptions)
}

const fetchConsentId = async (applicationId) => {
  const requestOptions = {
    "method": "GET",
    "headers": { "Content-Type": "application/json" },
  };
  const res = await fetchResponse(`/api/applications/${applicationId}/consentId`, requestOptions);
  return res.json();
};

export {
  fetchApplicationData,
  fetchApplication,
  fetchOnboardSummary,
  fetchRedirectURL,
  fetchAccountData,
  fetchAccountDataForMigration,
  fetchPasscode,
  getBankingPortalUrl,
  updateApplicationStatus,
  createFeeds,
  migrateFeeds,
  revokeConsent,
  fetchConsentId
}
