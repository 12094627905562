import * as PropTypes from "prop-types";
import { Connect } from 'connect-web-sdk';
import ConnectPage from "../consent-management/ConnectPage";
import OpenBankingLoadingView from "../open-banking-loading/OpenBankingLoadingView";
import { useState } from "react";

export default function OnboardOpenBankingInitiate({applicationId, connectedUrl, productRedirectUrl}) {
  const [isConnected, setIsConnected] = useState(false);
  const connectEventHandlers = {
    onDone: () => {
      setIsConnected(true);
      Connect.destroy();
    },
    onCancel: () => {},
    onError: () => {},
    onRoute: () => {},
    onUser: () => {},
    onLoad: () => {}
  };
  if (isConnected) {
    return (<OpenBankingLoadingView applicationId={applicationId} productRedirectUrl={productRedirectUrl} />)
  }

  return (<ConnectPage connectedUrl={connectedUrl} connectEventHandlers={connectEventHandlers}></ConnectPage>)
}

OnboardOpenBankingInitiate.propTypes = {
  applicationId: PropTypes.string,
  connectedUrl: PropTypes.string,
  productRedirectUrl: PropTypes.string
};







