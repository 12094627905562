import React from "react";
import {Checkbox, Label, Table} from "@myob/myob-widgets";
import * as _ from 'lodash';

function AccountsTable({accounts, selectedAccounts, setSelectedAccounts, source, reachSelectionLimit}){
  const isFromOpenBanking = source?.toLowerCase() === "OpenBanking".toLowerCase();
  const showDescriptionColumn = _.some(accounts, a => a.onboarded) || _.some(accounts, a => a.unsupportedDescription)
    _.some(accounts, a => a.unsupportedDescription);

  const tableColumns =
    [
      {key: 'name', description: 'Account name', visible: true},
      {key: 'number', description: 'Account number', visible: true},
      {key: 'type', description: 'Account type', visible: true},
      {key: 'description', description: '', visible: showDescriptionColumn},
    ];

  const inactiveTypes =
    [
      {key: 'onboarded', description: 'Connected', tone: 'brand'},
      {key: 'foreign_currency', description: 'Foreign currency account', tone: 'warning'},
      {key: 'closed_account', description: 'Closed account', tone: 'neutral'},
      {key: 'incomplete_account_details', description: 'Incompatible account', tone: 'neutral'},
    ];

  _.forEach(accounts, (account, index) => account.id = index.toString())

  const selectableAccounts = _.filter(accounts, d => !d.onboarded);

  const isAllChecked = selectedAccounts.length > 0 &&
    selectedAccounts.length === selectableAccounts.length

  const selectAll = () =>
    setSelectedAccounts(prev => prev.length === selectableAccounts.length ? [] : selectableAccounts);

  const selectItem = (item) => {
    setSelectedAccounts(prev => !_.includes(prev, item) && !item.onboarded ?
      [...prev, item] : _.filter(prev, account => account.id !== item.id));
    }

  const onRowSelect = (id, isSelected) => {
    if (isSelected && reachSelectionLimit && isFromOpenBanking) return;
    selectItem(accounts[id]);
  }

  const reachLimitDisable = (item) => isFromOpenBanking && reachSelectionLimit && !_.includes(selectedAccounts, item)
  const getHeaderStyle = (rowKey) => {
    if(rowKey === "description") return {flex: "1.2", whiteSpace: "nowrap"}
    if(rowKey === "type") return {flex: "0.7", whiteSpace: "nowrap"}
    return {whiteSpace: "nowrap"}
  }
  const renderHeader = () => (
    <Table.Header>
      <Table.HeaderItem width={isFromOpenBanking ? "30px": "auto"} key="checkbox">
        {!isFromOpenBanking && <Checkbox
          name="bulk-select"
          label="Bulk select"
          hideLabel
          onChange={selectAll}
          checked={isAllChecked}
          indeterminate={selectedAccounts.length > 0 && !isAllChecked}
        />}
      </Table.HeaderItem>
      {tableColumns.map(c => c.visible && (
        <Table.HeaderItem key={c.key} style={getHeaderStyle(c.key)}>
          {c.description}
        </Table.HeaderItem>
      ))}
    </Table.Header>
  );

  const getDescriptionLabel = (a) => {
    let reason = a.onboarded? 'onboarded' : a.unsupportedDescription
    let type = _.find(inactiveTypes, ['key', reason]);
    return type? (<Label tone={type.tone} type="boxed" size="sm" style={{whiteSpace: "nowrap"}} >
      {type.description}
    </Label>) : '';
  }

  const renderRow = (a, index) => (
    <Table.Row
      key={a.id}
      isSelected={_.includes(selectedAccounts, a)}
      onRowSelect={onRowSelect}
      rowData={a}
      isInactive={a.onboarded || a.unsupportedDescription || reachLimitDisable(a)}
    >
      <Table.RowItem width="auto" cellRole="checkbox" valign="middle">
        <Checkbox
          name={`${a.id}-select`}
          label={`Select row ${index}`}
          hideLabel
          onChange={() => selectItem(a)}
          checked={_.includes(selectedAccounts, a)}
          disabled={a.onboarded || a.unsupportedDescription || reachLimitDisable(a)}
        />
      </Table.RowItem>
      <Table.RowItem columnName="Account name" textWrap="wrap" valign="middle">{a.name}</Table.RowItem>
      <Table.RowItem columnName={a.number? "Account number" : ""}  textWrap="wrap" valign="middle">{a.number}</Table.RowItem>
      <Table.RowItem columnName="Account type" valign="middle" style={{flex: "0.7"}}>{a.type}</Table.RowItem>
      {showDescriptionColumn &&
        <Table.RowItem valign="middle" style={{flex: "1.2"}}>
          {getDescriptionLabel(a)}
        </Table.RowItem>}
    </Table.Row>
  );

  return(
    <Table onRowSelect={onRowSelect}>
      {renderHeader()}
      <Table.Body onRowSelect={onRowSelect}>
        { _.map(accounts, renderRow) }
      </Table.Body>
    </Table>
  )
}

export default AccountsTable
