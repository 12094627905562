import Loading from "../../common/Loading";
import React, {useContext, useEffect, useCallback} from "react";
import {apiContext} from "../../ApiContext";
import {FullStory} from "@fullstory/browser";
import * as PropTypes from "prop-types";

function OpenBankingLoadingView({applicationId, productRedirectUrl}) {

  const api = useContext(apiContext);
  const pollingTimeout = 6000;
  const pollingInterval = 2000;

  useEffect(() => {
    FullStory('trackEvent', {
      name: 'Open Banking Loading page before finalise',
      properties: {
      },
    })
  }, []);

    const pollBfosForConsentId = useCallback(async (isPolling) => {
      if (!isPolling) return;

      try {
        const response = await api.fetchConsentId(applicationId);
        if (response.consentId) {
          const state = window.btoa(`source=OpenBanking&applicationId=${applicationId}&productRedirectUrl=${productRedirectUrl}`).toString();
          window.location.href = `/onboard/finalise?state=${state}&id=${response.consentId}`;
        } else {
          setTimeout(() => pollBfosForConsentId(isPolling), pollingInterval); // Poll every 2 seconds
        }
      } catch (error) {
        console.error("Error polling BFOS:", error);
        setTimeout(() => pollBfosForConsentId(isPolling), pollingInterval); // Retry on error
      }
    }, [api, applicationId, productRedirectUrl]);

  useEffect(() => {
    let isPolling = true;
    const timeout = setTimeout(() => {
      isPolling = false;
      window.location.href = `/onboard/openBanking/error`;
    }, pollingTimeout);

    pollBfosForConsentId(isPolling);

    return () => {
      clearTimeout(timeout);
      isPolling = false;
    }
  }, [pollBfosForConsentId]);

    return (
      <Loading message={'Please wait while we finalise your connection'}/>
    );
}

export default OpenBankingLoadingView;

OpenBankingLoadingView.propTypes = {
  applicationId: PropTypes.string,
  productRedirectUrl: PropTypes.string
};
