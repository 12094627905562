import React, {useContext, useState} from "react";
import {Box, Button, ButtonRow, Card, Modal, OpenExternalLinkIcon, PageHead} from "@myob/myob-widgets";
import {apiContext} from "../../ApiContext";
import "../../common/Common.css"
import RedirectLogo from "../../assets/redirect-logo.png";

function MyobTermsView({applicationId, institutionId, countryId, applicationType, redirectUrl, setLoading, setErrorType}) {
  const [showModal, setShowModal] = useState(false);
  const api = useContext(apiContext)
  const cancelApplication = async() => {
    const decodedRedirectUrl = decodeURIComponent(redirectUrl);
    try {
      setLoading(true);
      await api.updateApplicationStatus(applicationId, "TCDeclined");
      redirectTo(decodedRedirectUrl);
    } catch (e) {
      setLoading(false);
      e.status === 409 ? setErrorType('applicationCannotProceed') : setErrorType('applicationFailed');
    }
  }

  const goToInstitutionPortal = async () => {
    const requestBody = {
      applicationId: applicationId,
      institutionId: institutionId,
      countryId: countryId,
      applicationType: applicationType,
      redirectUrl: decodeURIComponent(redirectUrl)
    }
    try {
      setShowModal(false);
      setLoading(true);
      const url = await api.fetchRedirectURL(requestBody);
      redirectTo(url);
    } catch (e) {
      setLoading(false);
      e.status === 409 ? setErrorType('applicationCannotProceed') : setErrorType('applicationFailed');
    }
  }

  const redirectTo = (url) => {
    window.location.href = url
  }

  const CardBody = () => (
    <Box as="ol" marginLeft="md" >
      <li>
        <b>Authorisation:</b>  You authorise MYOB Australia Pty. Ltd. (MYOB) as follows:
        <Box as="ul" marginLeft="md">
          <li>To obtain (and register for) any and all current data feeds as well as historical data feeds (where historical bank feeds are deemed necessary by you or MYOB) (Bank Feeds) from any financial institution (FS) and from any accounts at those financial institutions (including deposit, cheque, loan, mortgage, investment or any other bank accounts and any credit or debit card accounts) (Accounts).</li>
          <li>You confirm that you have all relevant authority to provide details of the Accounts to MYOB on behalf of the business that operates the Accounts (Business).</li>
          <li>The Business hereby grants MYOB a limited power of attorney to access the Accounts in order to provide the Bank Feeds.</li>
          <li>The Business is bound by the terms and conditions (particularly clause 5) set out in the <a target={"_blank"} rel={"noopener noreferrer"} href={"https://www.myob.com/au/support/customer-service/myob-legal-notices/EssentialsTermsofUse"}>MYOB Essentials Terms of Use</a>, which you have read and understood.</li>
        </Box>
      </li>
      <br/>
      <li>
        <b>Information:</b> You agree that the details of any Accounts and Bank Feeds that you provide to MYOB will be correct and complete in all respects.  You agree to keep all such information regarding the Accounts and Bank Feeds accurate and up to date.  You agree that the Business is bound by <a target={"_blank"} rel={"noopener noreferrer"} href={"https://www.myob.com/au/privacy-policy"}> MYOB’s Privacy Policy</a>, which you have read and understood.
      </li>
      <br/>
      <li>
        <b>Disclaimer:</b> Subject to any applicable laws, you acknowledge and agree that:
        <Box as="ul" marginLeft="md">
          <li>MYOB accepts no responsibility for the completeness or accuracy of any of the Bank Feeds;</li>
          <li>MYOB makes no representations about the suitability for any particular purpose of the Bank Feeds. You expressly agree that the Business has made its own enquiries about the suitability of the Bank feeds for its purposes.</li>
        </Box>
      </li>
      <br/>
      <li>
        <b>Limitation of liability:</b> MYOB excludes all liability for any direct, indirect, special, incidental, consequential or exemplary loss or damages, including damages for economic loss, loss of profits, goodwill, data loss or any intangible losses, arising from your use of, or reliance on, the Bank Feeds.
      </li>
      <br/>
      <li>
        <b>Indemnity:</b> You agree to indemnify MYOB from any and all claims, liability, damages, expenses and costs (including, but not limited to reasonable legal fees) caused by or arising from your use of the Bank Feeds.
      </li>
    </Box>
  )

  return (
    <div
      className={applicationType === "NEW" ? "page-block--sm animate terms-conditions-page-for-creation" : "page-block--sm animate terms-conditions-page-for-migration"}
      style={{padding: '2.4rem'}}
    >
      <PageHead title="Bank Feeds Terms and Conditions" />
      <Card
        body={<Card.Body child={<CardBody />} />}
      />
      <ButtonRow >
        <Button type="secondary" className={applicationType === "NEW" ? "decline-terms-conditions-for-creation" : "decline-terms-conditions-for-migration"} data-testid="decline button" onClick={() => cancelApplication()}>Decline</Button>
        <Button type="primary" data-testid="accept button" onClick={() => setShowModal(true)}> Accept and login <OpenExternalLinkIcon style={{marginLeft: '4px'}}/></Button>
      </ButtonRow>
      {showModal &&
        <Modal size="large" style={{width:'544px', top: 'calc(50vh - 221px)'}} title="We need to redirect to your bank" onCancel={() => setShowModal(false)}>
          <Modal.Body>
            <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
              <img src={RedirectLogo} alt="redirect-logo"/>
              <p style={{textAlign: 'center', lineHeight: '24px', fontSize: '16px', marginTop: '24px'}}>By selecting connect, you’ll be securely transferred to your bank to confirm your details</p>
            </Box>
          </Modal.Body>
          <Modal.Footer>
            <Button
              tone="neutral" variant="standard"
              className="btn btn-default"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Button>
            <Button
              className="btn btn-primary"
              onClick={() => {
                goToInstitutionPortal()
              }}>
              Connect
            </Button>
          </Modal.Footer>
        </Modal>
      }
    </div>
  );
}
export default MyobTermsView;
