import React, {useContext, useEffect, useState} from "react";
import ErrorView from "../../common/ErrorView";
import Loading from "../../common/Loading";
import MyobTermsView from "./MyobTermsView";
import {apiContext} from "../../ApiContext";

function OnboardInitiate({ portalCode, inputApplicationId, redirectUrl}) {
  const [errorType, setErrorType] = useState(null);
  const [isLoading, setLoading] = useState(true)
  const [applicationId, setApplicationId] = useState(null);
  const [institutionId, setInstitutionId] = useState(null);
  const [countryId, setCountryId] = useState(null);
  const [applicationType, setApplicationType] = useState(null);
  const api = useContext(apiContext)

  const updateState = (response) => {
    setApplicationId(response.applicationId);
    setInstitutionId(response.institutionId);
    setCountryId(response.countryId);
    setApplicationType(response.applicationType);
  }

  const retrieveApplicationInfo = async () => {
    try {
      const applicationInfo = portalCode?  await api.fetchApplicationData(portalCode) : await api.fetchApplication(inputApplicationId)
      updateState(applicationInfo)
      setLoading(false)
    } catch (e){
      if (e.message !== 'Unauthorized') {
        setLoading(false)
        setErrorType("somethingWentWrong");
      }
    }
  }

  useEffect(() => {
    if (!applicationId) {
      retrieveApplicationInfo()
    }
  }, []);

  return (
    <div>
      {isLoading ?
        <Loading message='loading'/> :
          errorType ? (
            <ErrorView type={errorType} productRedirectUrl={redirectUrl}/>
          ) :
          <MyobTermsView
            applicationId={applicationId}
            institutionId={institutionId}
            countryId={countryId}
            applicationType={applicationType}
            redirectUrl={redirectUrl}
            setLoading={setLoading}
            setErrorType={setErrorType}
          />
      }
    </div>
  );
}

export default OnboardInitiate;
